@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;600&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

:root {
  --radius: 0.2rem;
  --color-primary: #1381c9;
  --color-gray: #b3c4cf;
  font-family: 'Exo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f2faff;
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 40rem) 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  grid-row-gap: 1rem;
  grid-template-areas:
    'header header header'
    'l main r'
    'l footer r';

  > main {
    grid-area: main;
  }

  > footer {
    grid-area: footer;
  }
}

p,
ul {
  margin: 2rem 0;
}

p,
li {
  font-size: 1.125rem;
  line-height: 1.625;
  color: #314159;
}

h1 {
  font-weight: 600;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 1rem;

  > form {
    color: #333;
  }
}

a {
  &,
  &:visited {
    color: var(--color-primary);
  }
}

form {
  display: grid;

  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 1rem;
  grid-template-areas:
    'label x'
    'email submit';

  @media (max-width: 450px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'label'
      'email'
      'submit';
  }

  > label {
    grid-area: label;
    font-weight: bold;
  }
  > input[type='email'] {
    grid-area: email;
  }
  > input[type='submit'] {
    grid-area: submit;
  }
}

.state {
  --border-color: var(--color-gray);
  background: white;
  padding: 2rem;
  border-radius: var(--radius);
  border: 2px solid var(--border-color);

  &[data-active] {
    box-shadow: 0 0.5rem 2rem rgba(6, 49, 77, 0.25);
    --border-color: var(--color-primary);
  }

  &.-subscribed {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      height: 2rem;
      width: 0;
      left: 50%;
      bottom: 100%;
      border: inherit;
    }

    @media (max-width: 450px) {
      display: block;
    }
  }
}

.links {
  a + a {
    margin-left: 1ch;
  }

  @media (max-width: 450px) {
    a {
      display: block;
      margin: 0 !important;
    }
  }
}

footer {
  padding: 0 1rem;
}

input {
  appearance: none;
  font-size: 1rem;
  border: none;
}

input[type='email'] {
  border: 2px solid var(--color-gray);
  padding: 0.5rem;

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }
}

input[type='submit'] {
  color: white;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: var(--radius);
  background: var(--color-primary);
  cursor: pointer;

  &:focus {
    outline: 2px solid var(--color-primary);
    outline-offset: 2px;
  }
}
